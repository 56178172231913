/* You can add global styles to this file, and also import other style files */

.body-color {
    background-color: #eaeef7;
    /* zoom: 85%; */
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !important;
}

body {
    font-size: 14px !important;
}


/* Drag & Drop Card CSS*/

.grid-stack-instance-4853>.grid-stack-item>.grid-stack-item-content {
    background-color: white !important;
    border: 1px solid #dfdfdf !important;
    border-radius: 5px !important;
}

#chartDiv0p svg {
    width: 100% !important;
    height: 100% !important;
}


/*----------------- c3 Chart Customization CSS --------- */

#netcoll.c3 svg,
#expectcol.c3 svg,
#allowedchart.c3 svg,
#netcharge.c3 svg {
    font-size: 12px;
    font-weight: 500;
}

.c3-chart-arcs-background {
    fill: #e0e0e0;
}

#allowedchart.c3 svg {
    fill: transparent;
}


/* ------------------ Google Chart CSS------------- */

.chart {
    margin-top: auto !important;
    margin-bottom: auto !important;
}


/* h6, .h6{
    font-size: 0.8rem !important;
} */


/* Kendo Grid Changes */

.k-grouping-row td,
.k-group-footer td {
    font-weight: 500 !important;
}

.k-grid-toolbar.header-color {
    background-color: transparent !important;
}

.page-template {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-size: 13px !important;
}

.page-template .grid-header {
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;
    font-size: 13px !important;
}

.light-blue-header.k-grid .k-grid-header {
    background: #004385 !important;
    color: white !important;
}


/* .color-header.k-grid th {
  padding: 4px 12px !important;
} */

.color-header.k-grid .k-grid-header {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
    background: #076bb2 !important;
    color: #ffffff !important;
    font-weight: bold;
}

.k-grid .k-grid-header .k-filter-row {
    background-color: #f6f6f6 !important;
}

.small-header.k-grid .k-grid-header .k-header>.k-link {
    padding: 5px 12px !important;
    font-weight: 500;
}

group-table tbody .k-group-cell {
    color: #656565;
    background-color: #ffffff !important;
}

.group-table .k-group-footer td,
.group-table .k-grouping-row td,
.group-table tbody .k-group-cell {
    color: #656565;
    background-color: #ffffff !important;
}


/* HOVER */

.k-grid tbody tr:hover,
.k-grid tbody tr.k-state-hover {
    color: #005a96 !important;
    font-weight: bold;
    background-color: rgba(32, 107, 196, .1)!important;
}

.quality-grid.k-grid tbody tr:hover,
.quality-grid.k-grid tbody tr.k-state-hover {
    color: initial !important;
    font-weight: initial;
    background-color: #CEEBF7 !important;
}

.quality-grid.k-grid tbody td {
    padding: 5px;
}


/* Alternate Color */

.k-grid tr.k-alt {
    background-color: #f9f9f9 !important;
}

.k-grid td {
    border-width: 0 0px 1px 1px !important;
    /* border-color: #e0e3e7 !important; */
}

.k-grid-toolbar {
    padding: 2px !important;
}

.k-grid {
    color: #000000 !important;
}


/* total header row */

.footer-gridother.k-grid .k-grid-container {
    margin-top: 31px !important;
}

.footer-gridother.k-grid .k-grid-footer {
    position: absolute !important;
    top: 95px !important;
    width: 100%;
}

.footer-grid.k-grid .k-grid-container {
    margin-top: 29px !important;
}

.footer-grid.k-grid .k-grid-footer {
    position: absolute !important;
    top: 79px !important;
    width: 100%;
}

.footer-griddrilldown.k-grid .k-grid-container {
    margin-top: 33px !important;
}

.footer-griddrilldown.k-grid .k-grid-footer {
    position: absolute !important;
    top: 70px !important;
    width: 100%;
}

.footer-gridCP.k-grid .k-grid-container {
    margin-top: 33px !important;
}

.footer-gridCP.k-grid .k-grid-footer {
    position: absolute !important;
    top: 54px !important;
    width: 100%;
}

.footer-gridDT.k-grid .k-grid-container {
    margin-top: 29px !important;
}

.footer-gridDT.k-grid .k-grid-footer {
    position: absolute !important;
    top: 88px !important;
    width: 100%;
}

.footer-gridwithoutgroupheader.k-grid .k-grid-container {
    margin-top: 29px !important;
}

.footer-gridwithoutgroupheader.k-grid .k-grid-footer {
    position: absolute !important;
    top: 88px !important;
    width: 100%;
}

.footersingle-gridwithoutgroupheader.k-grid .k-grid-container {
    margin-top: 38px !important;
}

.footersingle-gridwithoutgroupheader.k-grid .k-grid-footer {
    position: absolute !important;
    top: 69px !important;
    width: 100%;
}

.footersingle-gridwithoutgroupheaderNewAR.k-grid .k-grid-container {
    margin-top: 25px !important;
}

.footersingle-gridwithoutgroupheaderNewAR.k-grid .k-grid-footer {
    position: absolute !important;
    top: 20px !important;
    width: 100%;
}

.footersingle-gridwithoutgroupheaderprocidercomp.k-grid .k-grid-container {
    margin-top: 30px !important;
}

.footersingle-gridwithoutgroupheaderprocidercomp.k-grid .k-grid-footer {
    position: absolute !important;
    top: 56px !important;
    width: 100%;
}


/* end total header row */

.grid-padding.k-grid th,
.grid-padding.k-grid td {
    padding: 0 !important;
}

.div-padding {
    padding: 8px 12px;
}


/* Hover for Last Updated */

.custom-tooltip {
    display: inline-block;
    position: relative;
    /* border-bottom: 1px dotted #666; */
    text-align: left;
}

.custom-tooltip .left {
    min-width: 550px;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    top: 50%;
    right: 100%;
    margin-right: 20px;
    transform: translate(0, -5%);
    padding: 10px 20px;
    color: #444444;
    background-color: #ffffff;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    display: none;
}

.custom-tooltip:hover .left {
    display: block;
}

.custom-tooltip .left i {
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    overflow: hidden;
}

.tooltip .left i::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: #ffffff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

/* Filter Collapse */

.collapse-overlay {
    position: absolute !important;
    top: -45px;
    left: 0;
    z-index: 100;
    width: 100%;
}

.tag-data {
    font-size: 0.75rem;
    color: #206bb2;
    ;
    background-color: rgb(216 232 251);
    ;
    /* border: 1px solid #cdcdcd; */
    border-radius: 3px;
    padding: 0 0.5rem;
    line-height: 2em;
    display: inline-flex;
    cursor: default;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bgpractice {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.bggroup {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.pointer-cursor {
    cursor: pointer !important;
}

.pointer-none {
    pointer-events: none !important;
}


/* Angular Multiselect */

.custom-class-example .list-filter input,
.custom-class-example {
    color: #656565 !important;
}

.custom-class-example .c-token {
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    background: #f6f6f6 !important;
    border-radius: 0px !important;
}

.custom-class-example .selected-list .c-list .c-token .c-label {
    color: #656565 !important;
}

.custom-class-example .selected-list .c-list .c-token .c-remove svg {
    fill: #656565 !important;
}

.custom-class-example .pure-checkbox label::before {
    border-color: rgba(0, 0, 0, 0.08) !important;
    background-color: #fff !important;
}

.custom-class-example .pure-checkbox input[type="checkbox"]:checked+label:before {
    border-color: #52beff !important;
    /* background-color: #ff6358 !important; */
}

.custom-class-example .list-filter {
    height: 40px !important;
}

.custom-class-example .c-btn {
    color: rgba(0, 0, 0, 0.3) !important;
    min-height: calc(1.4285714286em + 12px) !important;
    padding: 4px !important;
    border: 1px solid #ebebeb !important;
    border-radius: 0 !important;
}

.custom-class-example .selected-list .c-list .c-token {
    margin-top: 0 !important;
}

.custom-class-example .pure-checkbox input[type="checkbox"]+label {
    font-weight: normal !important;
    color: #656565 !important;
}

.custom-class-example .list-message {
    color: #656565 !important;
    font-weight: normal !important;
}

.custom-class-example .dropdown-list ul li {
    padding: 4px 10px !important;
}

.custom-class-example .dropdown-list ul li:last-child {
    padding-bottom: 4px !important;
}

.custom-class-example .dropdown-list ul li:first-child {
    padding-top: 4px !important;
}

.custom-class-example .pure-checkbox input[type="checkbox"]+label:before {
    border-radius: 0 !important;
    border: 1px solid;
}

.custom-class-example .selected-item {
    background-color: #e5f4ff;
}

.custom-class-example .selected-list .countplaceholder {
    color: black !important;
}

.custom-class-example .c-btn.disabled {
    background-color: #fff !important;
}

.black-box {
    display: block;
    position: fixed;
    z-index: 1300;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.k-i-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 64px;
    background-color: rgba(255, 255, 255, 0.3);
    color: #ff6757;
}

.selfScroll {
    height: auto !important;
    min-height: 30px !important;
}


/* End */

.header-hide .k-grid-header {
    display: none !important;
}

.k-grid th,
.k-grid td,
.whole-cell {
    padding: 5px 5px 5px 5px !important;
    border-width: 0 0px 0px 0px !important;
}

.whole-cell.k-grid th,
.whole-cell.k-grid td {
    padding: 0px 5px !important;
    border-width: 0 0px 0px 0px !important;
}

.whole-cell .k-grid-toolbar {
    padding: 0px 0px 0px 0px !important;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected>td {
    background-color: rgba(32, 107, 196, .1)!important;
    font-weight: bold !important;
}

.k-breadcrumb-link {
    color: #0d5193 !important;
    /* cursor:pointer; */
}

.spinner-border {
    width: 1rem !important;
    height: 1rem !important;
}


/* .k-listview {
    border-color: rgba(0, 0, 0, 0.08);
    color: #656565;
    background-color: transparent !important;
} */


/* .k-i-add:before, .k-i-plus:before, .k-plus:before {
    content: "\E014" !important;
  }
  .k-i-kpi-trend-equal:before, .k-i-minus:before, .k-minus:before {
    content: "\E015" !important;
  } */

.btn-quality-primary {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #3e4fb1 !important;
    border-color: #33439e !important;
}

.btn-quality-primary:hover {
    color: #fff !important;
    background-color: #37459a !important;
    border-color: #3e4fb1 !important;
}


/*To match WEBEMR theme =======================*/

.font-family-tahoma {
    font-family: Tahoma, "Roboto", "Helvetica Neue", Helvetica, sans-serif, Arial !important;
}

.font-14-header {
    font-size: 14px;
}

.font-13-body {
    font-size: 13px !important;
}

.text-primary {
    color: #3e4fb1 !important;
}

.font-14-header {
    font-size: 14px !important;
}

.font-13-body {
    font-size: 13px !important;
}

.text-primary {
    color: #3e4fb1 !important;
}

.quality-grid .k-grid-toolbar {
    padding: 0px !important;
}


/*To match WEBEMR theme END =======================*/

.modal-blur {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

